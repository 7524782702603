import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'jquery/dist/jquery';
import 'popper.js/dist/popper.min';
// Highlighting for code blocks
import 'prismjs/themes/prism.css';
import React from 'react';
import './src/styles/global.css';

const App = ({ element }) => (
  <>
    {element}
  </>
);
// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element }) => <App element={element} />;
